<template>
  <div :style="containerStyle" class="tw-video relative z-0">
    <video
      v-if="element.options.videoSrc?.url"
      :id="`video-${element.id}`"
      :src="element.options.videoSrc?.url"
      :style="style"
      controlsList="nodownload nofullscreen"
      v-bind="videoAttrs"
      @contextmenu.prevent
    />

    <div v-else class="absolute z-20 flex size-full items-center justify-center bg-[#000000]">
      <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="120"
          height="120"
          rx="60"
          fill="#151B26"
          fill-opacity="0.5"
        />
        <path d="M45 48.8888V72.8138C45 77.7138 50.325 80.7888 54.575 78.3388L64.95 72.3638L75.325 66.3638C79.575 63.9138 79.575 57.7888 75.325 55.3388L64.95 49.3388L54.575 43.3638C50.325 40.9138 45 43.9638 45 48.8888Z" fill="white" />
      </svg>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { type PropType, computed, onBeforeMount } from 'vue';
import { fetchImage, getAssetFromCdn } from '@shared/utils/helpers';
import type { Element as ElementType } from '@shared/types/model';

const props = defineProps({
  tagName: { type: String, default: 'img' },
  value: { type: String, default: '' },
  style: { type: Object, default: () => ({}) },
  attr: { type: Object as PropType<ElementType<'image'>['attr']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'image'>>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false }
});

const videoAttrs = computed(() => {
  let autoplay = false;
  let controls = true;
  let muted = !props.element.options.playWithSound;

  if (!props.isEditorMode && props.element.options.autoplay) {
    autoplay = true;
    controls = false;
    muted = true;
  }

  return {
    poster: props.element.options.videoSrc?.cover,
    autoplay,
    loop: props.element.options.loop,
    controls,
    muted
  }
})

const style = computed(() => {
  const backgroundImage = `url(${getAssetFromCdn('editor/noVideo.png')})`;
  const defaultStyle = {
    ...props.style,
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    backgroundImage,
    backgroundColor: 'black',
  } as any;
  if (props.element.options.videoFullSize) {
    return {
      ...defaultStyle,
      position: 'absolute',
      top: '0',
      left: '0',
    };
  } else {
    return defaultStyle;
  }
});

const containerStyle = computed(() => {
  if (props.element.options.videoFullSize) {
    return {
      ...props.style,
      'padding-bottom': '56.25%',
      height: '0',
      width: '100%',
    };
  } else {
    return {
      ...props.style,
      maxWidth: '100%',
      width: `${props.element.options.videoWidth}px`,
      height: `${props.element.options.videoHeight}px`
    };
  }
});

onBeforeMount(() => {
  const { cover } = props.element.options.videoSrc || {};
  if (cover) fetchImage(cover);
});
</script>

<style lang="postcss" scoped>
.tw-broken-image {
  @apply bg-no-repeat bg-center bg-cover flex items-center justify-center;
}
</style>
